import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

// lodash
import chunk from 'lodash/chunk';

// project imports
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import Slider from '../components/Slider';
// import BannerCTA from '../components/BannerCTA';

// eslint-disable-next-line react/prop-types
const VisionIcon = ({ fill = '#E9204F', size = 60, className = '' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 60 60`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30 6.5625C14.4694 6.5625 1.875 22.7541 1.875 28.3425C1.875 33.9309 14.4694 50.1225 30 50.1225C34.3266 50.1225 38.4234 48.8634 42.0863 46.9303L52.1063 53.4375V39.0806C55.8722 34.9556 58.125 30.7041 58.125 28.3425C58.125 22.7541 45.5325 6.5625 30 6.5625ZM30 42.5475C22.2347 42.5475 15.9375 36.1875 15.9375 28.3434C15.9375 20.4975 22.2347 14.1394 30 14.1394C37.7672 14.1394 44.0625 20.4975 44.0625 28.3434C44.0625 36.1875 37.7672 42.5475 30 42.5475Z"
        fill={fill}
      />
      <path
        d="M30.0371 21.6001C29.123 21.6001 28.2455 21.751 27.4215 22.022C27.9462 22.4645 28.3681 23.0161 28.658 23.6383C28.9478 24.2605 29.0985 24.9384 29.0996 25.6248C29.0996 28.2385 27.0015 30.3592 24.4121 30.3592C23.4087 30.3562 22.4336 30.0264 21.6343 29.4198C21.6137 29.6533 21.6022 29.8876 21.5996 30.122C21.5996 34.8282 25.3768 38.6448 30.0371 38.6448C34.6974 38.6448 38.4746 34.8282 38.4746 30.122C38.4746 25.4148 34.6974 21.6001 30.0371 21.6001Z"
        fill={fill}
      />
    </svg>
  );
};
// eslint-disable-next-line react/prop-types
const MissionIcon = ({ fill = '#E9204F', size = 60, className = '' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 60 60`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.15"
        d="M29.4118 59.9998C45.6554 59.9998 58.8235 46.8317 58.8235 30.588C58.8235 14.3444 45.6554 1.17627 29.4118 1.17627C13.1681 1.17627 0 14.3444 0 30.588C0 46.8317 13.1681 59.9998 29.4118 59.9998Z"
        fill={fill}
      />
      <path
        d="M30.5875 58.8235C46.8312 58.8235 59.9993 45.6554 59.9993 29.4118C59.9993 13.1681 46.8312 0 30.5875 0C14.3439 0 1.17578 13.1681 1.17578 29.4118C1.17578 45.6554 14.3439 58.8235 30.5875 58.8235Z"
        fill={fill}
      />
      <path
        d="M30.588 5.88232C43.5827 5.88232 54.1174 16.417 54.1174 29.4117C54.1174 42.4064 43.5827 52.9411 30.588 52.9411C17.5933 52.9411 7.05859 42.4064 7.05859 29.4117C7.05859 16.417 17.5933 5.88232 30.588 5.88232Z"
        fill="#191919"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5885 11.7646C40.3349 11.7646 48.2355 19.6652 48.2355 29.4117C48.2355 39.1576 40.3349 47.0588 30.5885 47.0588C20.842 47.0588 12.9414 39.1576 12.9414 29.4117C12.9414 19.6652 20.842 11.7646 30.5885 11.7646Z"
        fill={fill}
      />
      <path
        d="M30.5889 17.647C37.0866 17.647 42.3536 22.9146 42.3536 29.4117C42.3536 35.9087 37.0866 41.1764 30.5889 41.1764C24.0913 41.1764 18.8242 35.9087 18.8242 29.4117C18.8242 22.9146 24.0913 17.647 30.5889 17.647Z"
        fill="#191919"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5874 23.5293C33.8363 23.5293 36.4698 26.1628 36.4698 29.4116C36.4698 32.6599 33.8363 35.294 30.5874 35.294C27.3386 35.294 24.7051 32.6599 24.7051 29.4116C24.7051 26.1628 27.3386 23.5293 30.5874 23.5293Z"
        fill="#E64C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3521 43.5293H44.7051L52.3521 51.1764H59.9992L52.3521 43.5293Z"
        fill="#F0C419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3521 58.8234V51.1764L44.7051 43.5293V51.1764L52.3521 58.8234Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3521 43.5293H44.7051L52.3521 51.1764H59.9992L52.3521 43.5293Z"
        fill="#F0C419"
      />
      <path
        opacity="0.15"
        d="M29.6698 28.4935L8.89453 49.2688C9.29313 49.7015 9.70448 50.1223 10.1281 50.5306L30.9175 29.7411C31.0017 29.6597 31.069 29.5624 31.1152 29.4547C31.1615 29.3471 31.1858 29.2313 31.1868 29.1141C31.1878 28.997 31.1655 28.8808 31.1211 28.7724C31.0768 28.6639 31.0113 28.5654 30.9284 28.4826C30.8456 28.3997 30.747 28.3342 30.6386 28.2898C30.5302 28.2455 30.414 28.2231 30.2968 28.2242C30.1797 28.2252 30.0639 28.2495 29.9562 28.2958C29.8486 28.342 29.7512 28.4092 29.6698 28.4935Z"
        fill={fill}
      />
      <path
        d="M53.1554 52.8799C52.9295 52.8799 52.703 52.794 52.5307 52.6216L29.9648 30.0357C29.8064 29.8687 29.7196 29.6465 29.7227 29.4163C29.7259 29.1862 29.8187 28.9664 29.9816 28.8037C30.1444 28.6411 30.3643 28.5484 30.5944 28.5455C30.8245 28.5426 31.0467 28.6296 31.2136 28.7881L53.7801 51.3734C53.9033 51.4969 53.9872 51.6542 54.0211 51.8254C54.055 51.9966 54.0374 52.174 53.9705 52.3351C53.9037 52.4963 53.7906 52.6341 53.6455 52.731C53.5004 52.828 53.3299 52.8798 53.1554 52.8799Z"
        fill="#E57E25"
      />
    </svg>
  );
};

// const benefits = [{}, {}, {}, {}, {}, {}, {}, {}];

const sliderOptions = {
  dots: true,
  arrows: true,
  autoPlay: false,
  draggable: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  adaptiveHeight: true,
  swipeToSlide: false,
  swipe: false,
  centerMode: true,
  centerPadding: '0',
};

const AboutUsPage = ({ data, location }) => {
  const founders = [
    {
      avatar: data.Rahul.childImageSharp.gatsbyImageData,
      name: 'Rahul Kumar',
      designation: 'Director - Product & Sales',
    },
    {
      avatar: data.Siddhartha.childImageSharp.gatsbyImageData,
      name: 'Sid Srivastava',
      designation: 'Director - Business, Operations',
    },
    {
      avatar: data.Mahip.childImageSharp.gatsbyImageData,
      name: 'Mahip Kaushal',
      designation: 'Director - Project Management, Delivery & QA',
    },
    {
      avatar: data.Chitra.childImageSharp.gatsbyImageData,
      name: 'Chitra Pareek',
      designation: 'Chief Design Officer (Experience Design & Branding)',
    },
    {
      avatar: data.Reynold.childImageSharp.gatsbyImageData,
      name: 'Reynold Roy',
      designation: 'Chief Tech Architect (DB, DevOps, Blockchain, Compliances)',
    },
    {
      avatar: data.Krati.childImageSharp.gatsbyImageData,
      name: 'Krati Vijay',
      designation: 'Human Resource Manager',
    },
  ];

  const coreTeam = [
    {
      avatar: data.Asha.childImageSharp.gatsbyImageData,
      name: 'Asha',
      designation: 'iOS Engineering',
    },
    {
      avatar: data.Charu.childImageSharp.gatsbyImageData,
      name: 'Charu',
      designation: 'Experience Design',
    },
    {
      avatar: data.Ashutosh.childImageSharp.gatsbyImageData,
      name: 'Ashutosh',
      designation: 'FullStack Engineering',
    },
    {
      avatar: data.Utkarsh.childImageSharp.gatsbyImageData,
      name: 'Utkarsh',
      designation: ' Backend & Database',
    },
    {
      avatar: data.Mala.childImageSharp.gatsbyImageData,
      name: 'Mala',
      designation: 'Backend & Database',
    },
    {
      avatar: data.Ujwal.childImageSharp.gatsbyImageData,
      name: 'Ujwal',
      designation: 'Android Engineering',
    },
    {
      avatar: data.Vaibhav.childImageSharp.gatsbyImageData,
      name: 'Vaibhav',
      designation: 'FullStack Engineering',
    },
    {
      avatar: data.Hitesh.childImageSharp.gatsbyImageData,
      name: 'Hitesh',
      designation: 'Backend & Database',
    },
    {
      avatar: data.Pradeep.childImageSharp.gatsbyImageData,
      name: 'Pradeep',
      designation: 'Android Engineering',
    },
    {
      avatar: data.Akash.childImageSharp.gatsbyImageData,
      name: 'Akashdeep',
      designation: 'Frontend Engineering',
    },
    {
      avatar: data.Sumit.childImageSharp.gatsbyImageData,
      name: 'Sumit',
      designation: 'Experience Design',
    },
    {
      avatar: data.Amisha.childImageSharp.gatsbyImageData,
      name: 'Amisha',
      designation: 'iOS Engineering',
    },

    {
      avatar: data.Anjali.childImageSharp.gatsbyImageData,
      name: 'Anjali',
      designation: 'Human Resource',
    },
    {
      avatar: data.Nitesh.childImageSharp.gatsbyImageData,
      name: 'Nitesh',
      designation: 'New Business Acquisition',
    },
    {
      avatar: data.Sandeep.childImageSharp.gatsbyImageData,
      name: 'Sandeep',
      designation: 'New Business Acquisition',
    },
    {
      avatar: data.Kirti.childImageSharp.gatsbyImageData,
      name: 'Kirti',
      designation: 'New Business Acquisition',
    },
    // {
    //   avatar: data.Santosh.childImageSharp.gatsbyImageData,
    //   name: 'Santosh',
    //   designation: 'Digital Marketing',
    // },
  ];

  const imageData = data.desktop.childImageSharp.fluid;

  const sources = [
    { aspectRatio: 1, src: '', srcSet: '' },
    { ...imageData, media: '(min-width: 1024px)' },
  ];

  return (
    <Layout pageId="about-us" location={location} footer={true}>
      <Seo title="About Us" />
      <Section id="hero" backgroundImage={sources}>
        <div className="hero-banner">
          <Container screenHeight>
            <span className="flex-1 flex items-center justify-center w-full mt-20 lg:mt-0">
              <div className="flex-1 flex flex-col items-start justify-center">
                <h1 className="ff-tertiary fs-46 fs-lg-48 fw-500 m-0">
                  <span className="text-primary fw-700">About</span> Us
                </h1>
                <h2
                  className="ff-tertiary fs-46 fs-lg-48 fw-500 leading-tight mt-12 mb-0"
                  style={{ maxWidth: 700 }}
                >
                  A team of Creative Minds designing best Apps and Website for
                  the World.
                </h2>
                <h4
                  className="fw-400 fs-16 mt-16 mb-0"
                  style={{ maxWidth: 728 }}
                >
                  At Hie HQ, we make sure that alongside growth of the company,
                  our employees and client grow by providing a great work-life
                  Culture in the Industry.
                </h4>
                <p
                  className="mt-12 fw-400 fs-16 text-white"
                  style={{ maxWidth: 680 }}
                >
                  Our team works hand in hand with the client and make sure that
                  the product that they are working on not only becomes success
                  but also leaves an impression on the user being easy to use
                  and keeping in mind the interest of its users.
                </p>
              </div>
              {/* <div
                className="hidden lg:grid grid-cols-none xl:grid-cols-1 gap-12 mt-4"
                style={{ width: '400px' }}
              >
                <div className="h-full pt-4 xl:pt-5 pb-5 xl:pb-10 px-4 xl:px-6 card">
                  <h4 className="flex items-center fs-32 fs-xl-40 fw-700 ff-primary text-white">
                    <VisionIcon size={50} className="mr-8" />
                    Our Vision
                  </h4>
                  <p className="fs-16 mt-2 xl:mt-4">
                    We paint the bright future for the businesses working on
                    digital landscapes and firmly believe in providing
                    productive long-term business relationships.
                  </p>
                </div>

                <div className="h-full pt-4 xl:pt-5 pb-5 xl:pb-10 px-4 xl:px-6 card">
                  <h4 className="flex items-center fs-32 fs-xl-40 fw-700 ff-primary text-white">
                    <MissionIcon size={50} className="mr-8" />
                    Our Mission
                  </h4>
                  <p className="fs-16 mt-2 xl:mt-4">
                    Handover contemporary and futuristic web and mobile software
                    solutions to the businesses ranging from startups to
                    enterprises with affordability and satisfaction.
                  </p>
                </div>
              </div> */}
            </span>
          </Container>
        </div>
      </Section>

      <Section id="hero">
        <Container>
          <div className="grid grid-cols-none xl:grid-cols-2 gap-12">
            <div className="h-full pt-8 xl:pt-10 pb-10 xl:pb-20 px-8 xl:px-12 card">
              <h4 className="flex items-center fs-32 fs-xl-48 fw-700 ff-primary text-white">
                <MissionIcon size={50} className="mr-8" />
                Our Mission
              </h4>
              <p className="fs-18 mt-4 xl:mt-8">
                Our mission is to adapt to the ever-evolving space of Digital
                universe & outline excellent experiences with each product we
                create.
              </p>
            </div>

            <div className="h-full pt-8 xl:pt-10 pb-10 xl:pb-20 px-8 xl:px-12 card">
              <h4 className="flex items-center fs-32 fs-xl-48 fw-700 ff-primary text-white">
                <VisionIcon size={50} className="mr-8" />
                Our Vision
              </h4>
              <p className="fs-18 mt-4 xl:mt-8">
                We aim at creating a community that believes in co-building
                products.
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section id="our-team" backgroundColor="#FFF">
        <Container className="flex-col">
          <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
            <SectionHeading theme="dark" className="flex flex-col">
              <span className="fw-300">We, the people at HQ</span>
              <span className="text-black fs-24 mb-3 mt-3 lg:mt-9">
                Get to know the hero-hands
              </span>
            </SectionHeading>

            <div className="grid-container w-full mt-16 lg:mt-24">
              {founders.map((founder, index) => (
                <div key={index} className="card text-black">
                  <div className="flex lg:hidden">
                    <div className="card__avatar">
                      <GatsbyImage
                        image={founder.avatar}
                        alt={founder.name}
                        className="w-full card__image"
                      />
                    </div>
                    <div className="ml-4 flex flex-col justify-center">
                      <div className="card__name">{founder.name}</div>
                      <div className="card__designation">
                        {founder.designation}
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <div className="card__avatar">
                      <GatsbyImage
                        image={founder.avatar}
                        alt={founder.name}
                        className="card__image"
                      />
                    </div>
                    <div className="card__name">{founder.name}</div>
                    <div className="card__designation">
                      {founder.designation}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </span>
        </Container>
      </Section>

      {/*
    <Section id="perks-benefits">
      <Container className="flex-col">
        <span className="flex-1 flex flex-col items-start justify-start w-full mt-2 mb-12 lg:mb-4">
          <SectionHeading className="flex flex-col">
            <span className="fs-24 mb-3">Why Hie HQ</span>
            <span className="fw-800">Perks & Benfits</span>
          </SectionHeading>

          <div className="w-full mt-16">
            <Slider
              settings={{ ...sliderOptions }}
              id={`text-black benefits slider__benefits flex items-center justify-center flex-wrap`}
            >
              {benefits.map((_, i) => {
                return (
                  <div
                    key={`benefits-${i}`}
                    className="mx-auto mb-2 text-white px-8 py-8 flex flex-col items-start justify-start benefit"
                  >
                    <span className="inline-block"></span>
                    <span className="fs-18 mt-10">Flexible Hiring Models</span>
                  </div>
                );
              })}
            </Slider>
          </div>
        </span>
      </Container>
    </Section> */}

      <Section id="our-core-members">
        <Container className="flex-col">
          <SectionHeading theme="light" className="flex flex-col">
            <span className="fs-24 mb-3 mt-9">Get to know the hero-hands</span>
          </SectionHeading>
          <div className="hidden lg:grid grid-container">
            {coreTeam.map(({ avatar, name, designation }, index) => (
              <div key={index} className="card">
                <div className="card__avatar">
                  <GatsbyImage
                    image={avatar}
                    alt={name}
                    className="w-full card__image"
                  />
                </div>
                <div className="card__name">{name}</div>
                <div className="card__designation">{designation}</div>
              </div>
            ))}
          </div>
          <div className="slider-container w-full mt-28 block lg:hidden">
            <Slider id="slider__core-members" settings={{ ...sliderOptions }}>
              {chunk(
                coreTeam.map(({ avatar, name, designation }, index) => (
                  <div key={index} className="card">
                    <div className="card__avatar">
                      <GatsbyImage
                        image={avatar}
                        alt={name}
                        className="w-full card__image"
                      />
                    </div>
                    <div className="card__name">{name}</div>
                    <div className="card__designation">{designation}</div>
                  </div>
                )),
                4,
              ).map((items, index) => (
                <div key={index} className="members-list">
                  {items}
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </Section>

      {/* <Section id="banner-cta" backgroundColor="#FFF">
      <Container>
        <BannerCTA
          title="Let’s Discuss your"
          highlightedText="Project"
          navigateText="Contact now"
          navigateLink="/contact-us"
        />
      </Container>
    </Section> */}
    </Layout>
  );
};

AboutUsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default AboutUsPage;

export const query = graphql`
  query {
    Rahul: file(relativePath: { eq: "about-us/rahul.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Siddhartha: file(relativePath: { eq: "about-us/siddhartha.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Mahip: file(relativePath: { eq: "about-us/mahip.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Chitra: file(relativePath: { eq: "about-us/chitra.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Reynold: file(relativePath: { eq: "about-us/reynold.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Ashutosh: file(relativePath: { eq: "about-us/ashutosh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Sumit: file(relativePath: { eq: "about-us/sumit.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Utkarsh: file(relativePath: { eq: "about-us/utkarsh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Pradeep: file(relativePath: { eq: "about-us/pradeep.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Akash: file(relativePath: { eq: "about-us/akash.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Charu: file(relativePath: { eq: "about-us/charu.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Vaibhav: file(relativePath: { eq: "about-us/vaibhav.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Asha: file(relativePath: { eq: "about-us/asha.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Nitesh: file(relativePath: { eq: "about-us/nitesh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Amisha: file(relativePath: { eq: "about-us/amisha.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Ujwal: file(relativePath: { eq: "about-us/ujwal.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Anjali: file(relativePath: { eq: "about-us/anjali.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Hitesh: file(relativePath: { eq: "about-us/hitesh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Krati: file(relativePath: { eq: "about-us/krati.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Mala: file(relativePath: { eq: "about-us/mala.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Sandeep: file(relativePath: { eq: "about-us/sandeep.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Kirti: file(relativePath: { eq: "about-us/kirti.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    Santosh: file(relativePath: { eq: "about-us/santosh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    desktop: file(relativePath: { eq: "about-us-page-banner.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 3080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
